<template>
  <StaticPage ref="staticPage">
    <div class="form_wrapper">
      <div class="class407408" v-if="$route.query.code == 407 || $route.query.code == 408">
        <div class="form_logo">
          <img alt />
        </div>
        <div class="from_box">
          <div class="main">
            <p v-html="$t('loginClient.error.existingClientPortalAccess')"></p>
            <router-link to="/login" class="el-button purple_button">{{ $t('common.button.login') }}</router-link>
            <small>{{ $t('loginClient.error.tip') }}</small>
            <router-link to="/forgetPassword" class="el-button reset_button">
              {{ $t('common.button.reset') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="default" v-else>
        <p class="title">{{ $t('responseMsg.' + $route.query.code) }}</p>
        <p class="main">
          <u>
            <a :href="`https://${url}`">Click here</a>
          </u>
          to go back to the {{ $config.info.fullName }} homepage, or
          <a :href="'mailto:' + email">
            <u>send us an email</u>
          </a>
          to let us know you've encountered an error.
        </p>
        <p class="code">Error code : {{ $route.query.code }}</p>
      </div>
    </div>
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/StaticPage';
export default {
  components: { StaticPage },
  data() {
    return {
      url: '',
      email: ''
    };
  },
  mounted() {
    this.url = this.$refs.staticPage.url;
    this.email = this.$refs.staticPage.email;
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/error.scss';
</style>
