<template>
  <div id="loginClient">
    <div class="banner">
      <div class="inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$config.info.getEmail('svg'),
      address: this.$config.info.getAddress('svg'),
      url: this.$config.info.getUrl('svg'),
      platformUrl: this.$config.info.getUrl('svg')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/login.scss';
</style>
